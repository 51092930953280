.map {
  background-color: $primary-color;
  border: 0;
  display: block;
  height: 300px;
  width: 100%;
  @include bp-md {
    height: 400px;
  }
}
