.container {
  max-width: 1152px;
  margin: 0 auto;
  padding: 0 1rem;

  &-narrow {
    max-width: 600px;
  }
}

.header {
  background: transparent;
}

.section {
  padding: 4rem 0;
  @include bp-lg {
    padding: 6rem 0;
  }

  &-light {
    background-color: $bg-light;
  }

  &-title {
    font-size: 2rem;
    text-align: center;
    &::after {
      background: $primary-color;
      display: block;
      content: '';
      width: 50px;
      height: 3px;
      margin: 0.75rem auto 0 auto;
    }
  }

  .text-center {
    text-align: center;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  &-item {
    padding: 0 15px;
    margin-bottom: 20px;
    width: 100%;

    @include bp-sm {
      width: calc(100% / 2);
    }
    @include bp-md {
      width: calc(100% / 3);
    }
  }
}

.footer {
  padding: 1.5rem 0;
  text-align: center;

  .text-sm {
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
