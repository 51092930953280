.btn {
  appearance: none;
  background: $primary-color;
  border: 0;
  border-radius: 0;
  color: $white;
  display: inline-block;
  padding: 0.75rem 2rem;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $transition-speed-normal;
  &:hover {
    background-color: $primary-light-color;
  }
}

.btn-link {
  color: $text-color;
  text-decoration: none;
  transition: color $transition-speed-normal;
  &:hover {
    color: $primary-color;
  }
}
